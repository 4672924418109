@import '../scss//variables';

._agenda {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    ._agenda__row {
        display: flex;
        justify-content: center;
        align-items: stretch;
        border-bottom: 1px solid $color-grey-3;

        &:last-child {
            border-bottom: none;
        }

        ._agenda__content__label {
            font-size: 1.6rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        ._agenda__day {
            background-color: $color-primary;
            color: $color-primary-c;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 2rem;
            font-size: 1.8rem;
            text-transform: uppercase;
            font-weight: bold;
        }

        ._agenda__time {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 100px;
            width: 100px;
            padding: 2rem;
            font-size: 1.4rem;

            @media (min-width: $dim-breakpoint-sm) {
                flex: 0 0 150px;
                width: 150px;
            }
        }
        ._agenda__content {
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-direction: column;
            flex: 1 0 100px;
        }
        ._agenda__content__title {
            display: flex;
            justify-content: flex-start;
            text-align: left;
            align-items: center;
            padding: 2rem 2rem 2rem 0;
            font-size: 1.6rem;
            text-transform: uppercase;
            border-bottom: 1px solid $color-grey-3;

            @media (min-width: $dim-breakpoint-sm) {
                justify-content: center;
                text-align: center;
                padding: 2rem;
            }
            //@include ff-title();
            //font-weight: 500;
        }
        ._agenda__content__lecture {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-direction: column;
            border-bottom: 1px solid $color-grey-3;

            &:last-child {
                border-bottom: none;
            }
        }
        ._agenda__conent__lecture__title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: left;
            padding: 1rem 2rem 0 0;
            font-size: 1.6rem;
        }
        ._agenda__conent__lecture__speaker {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: right;
            padding: 1rem 2rem 1rem 1rem;
            font-size: 1.2rem;
            font-weight: 400;
            @include ff-title();
        }

        ._agenda__content__workshops {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: center;

            ._agenda__content__workshop {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 2rem 2rem 2rem 0;
                text-align: left;
                font-size: 1.4rem;
                border-bottom: 1px solid $color-grey-3;

                @media (min-width: $dim-breakpoint-sm) {
                    width: 50%;
                    justify-content: center;
                    align-items: center;
                    padding: 2rem;
                    text-align: center;

                    &:nth-child(2n + 1) {
                        border-right: 1px solid $color-grey-3;
                    }

                    &:nth-last-child(2) {
                        border-bottom: none;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
