@import '../scss/variables';

._view__register {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;
    padding: 2rem;

    ._view__register__submit {
        background-color: $color-primary;
        color: $color-primary-c;
        border: none;
        margin: 3rem 0 0;
        @include ff-title();
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 0.3rem;
        font-size: 1.6rem;
        font-weight: 700;
        cursor: pointer;

        i {
            margin: 0 0 0 1rem;
        }
    }

    ._view__register__amount {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        font-size: 2rem;
        padding: 2rem;

        b {
            text-transform: uppercase;
            color: $color-primary;
            margin-left: 1rem;
        }
    }
}

.a-regulamin {
    font-size: 1.4rem;
    font-weight: bold;
    color: $color-primary;
    text-align: center;
    width: 100%;
    display: block;
    text-transform: uppercase;
}