$color-primary: #0066ae;
$color-primary-c: #ffffff;

$color-grey-1: #aaaaaa;
$color-grey-2: #7e7e7e;
$color-grey-3: #dfdfdf;

$color-background: rgb(248, 248, 248);
$color-elem-background: #ffffff;
$color-elem-shadow: rgba(102, 102, 102, 0.15);

$color-red: rgb(218, 56, 56);

$color-nav-toggle: $color-grey-1;

@mixin custom-shadow(){
    box-shadow: 0 0 1rem 1rem $color-elem-shadow;
}