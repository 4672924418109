@import '../scss/variables';

._reservation-nav {
    $nav-height: 6rem;
    
    position: fixed;
    width: 100%;
    height: $nav-height;
    background-color: $color-elem-background;
    box-shadow: 0 0 1rem 0.5rem $color-elem-shadow;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 50;

    ._reservation-nav__go-back__button {
        margin: 0 0 0 1rem;
    }
}

._view__reservation__details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    min-height: 200px;

    h2 {
        margin: 0;
        padding: 1rem 0;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }
    h3 {
        margin: 0;
        padding: 1rem 0;
        font-size: 1.5rem;
        font-weight: 400;
        text-align: center;
    }

    ._button-light {
        margin-top: 2rem;
    }
    
}

._view__reservation__payments {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 2rem;

    h3 {
        font-size: 1.8rem;
        text-align: center;
    }

    ._view__reservation__payment {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        border-bottom: 1px solid $color-grey-3;

        &:last-child {
            border-bottom: none;
        }

        &.payment--header {
            color: $color-primary;
            font-weight: 700;
            border-bottom-color: $color-primary;
        }
    }

    ._view__reservation__payment__counter {
        flex: 0 0 10%;
        text-align: center;
        padding: 1rem 0.5rem;
    }

    ._view__reservation__payment__date {
        flex: 0 0 30%;
        text-align: center;
        padding: 1rem 0.5rem;
    }

    ._view__reservation__payment__method {
        flex: 0 0 30%;
        text-align: center;
        padding: 1rem 0.5rem;
    }
    ._view__reservation__payment__status {
        flex: 0 0 30%;
        text-align: center;
        padding: 1rem 0.5rem;
    }
}
._view__reservation__payments {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    font-size: 1.2rem;
    padding: 0;
    margin: 2rem 0 0;
}
._payments__payment__row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
        background-color: $color-primary;
        color: $color-primary-c;
        font-weight: bold;
    }

    &:nth-child(2n + 3){
        background-color: $color-grey-3;
    }

    & > div {
        flex: 0 0 30%;
        padding: 0.5rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        &.--full-width {
            flex: 0 0 100%;
            text-align: center;
        }
    }
}