._button-light {
    display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
        padding: 0 2rem;
        background-color: transparent;
        border: 1px solid $color-primary;
        border-radius: 0.3rem;
        @include ff-title();
        text-transform: uppercase;
        color: $color-primary;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        font-size: 1.4rem;

        .material-icons {
            font-size: 2.5rem;
            margin: 0 0.5rem;
        }

        &:hover {
            background-color: $color-primary;
            color: $color-primary-c;
        }
}