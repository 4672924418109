@import '../../scss/variables';

._text-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    ._text-field__label {
        @include ff-title();
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.4rem;
        color: $color-grey-2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 2rem 1rem;
        order: 1;
        transition: all 0.2s ease-in-out;
    }

    ._text-field__input {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $color-grey-1;
        padding: 1rem 2rem;
        font-size: 1.6rem;
        color: $color-primary;
        order: 2;
        transition: all 0.2s ease-in-out;
        border-radius: 0.3rem;

        &:focus {
            border-color: $color-primary;

            & + ._text-field__label {
                color: $color-primary;
            }
        }
    }

    ._text-field__error {
        color: $color-red;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem 2rem 1rem;
        order: 3;
        font-size: 1.2rem;
    }

    &._text-field--is-error {
        ._text-field__input {
            border-color: $color-red;
        }
    }
}